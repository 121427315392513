import React from "react";
import { AutorizadoPrimerNivel } from "./_AutorizadoPrimerNivel";
import { AutorizarPagoSegundoNivel } from "./_AutorizarPagoSegundoNivel";
import { RechazarPagoSegundoNivel } from "./_RechazarPagoSegundoNivel";
import { Col, Row } from "antd";
import { useSelector } from "react-redux";
import { correosAutorizaOPSegundoNivel } from "../../../../../../../constants/cons";

export const SegundoNivel = () => {
  const { email } = useSelector((state) => state.userReducer.user); //

  const puede = correosAutorizaOPSegundoNivel.includes(email);

  return (
    <div>
      <AutorizadoPrimerNivel />

      {puede ? (
        <Row gutter={10} style={{ marginTop: 10 }}>
          <Col span={12}>
            <RechazarPagoSegundoNivel />
          </Col>
          <Col span={12}>
            <AutorizarPagoSegundoNivel />
          </Col>
        </Row>
      ) : (
        <div style={{marginTop: 10, color: 'red'}}>Espera de autorizacion 2° nivel</div>
      )}
    </div>
  );
};
