import { types } from "../types/types";
import { composeErrorNotification, composeSuccessNotification } from "../../utils/composeNotification";
import { notification } from "./notificationActions";
import { get, post } from "../../utils/fetch";
import { URL_BASE_USUARIOS } from "../../constants/url";
import { validaUsuario } from "../../utils/validaUsuario";

export const startGetUsuarios = () => {
  return async (dispatch) => {
    const result = await get(`${URL_BASE_USUARIOS}/GetUsers`);
    if (result.code === 0) {
      dispatch(storeUsuarios(result.data));
    } else {
      dispatch(notification(composeErrorNotification("GetUsers")));
    }
  };
};

const storeUsuarios = (payload) => ({
  type: types.storeUsuarios,
  payload,
});

//////////////////////////////////

export const changeUsuarioForm = (payload) => ({
  type: types.changeUsuarioForm,
  payload,
});

///

export const startPostUsuario = () => {
  return async (dispatch, getState) => {
    const { usuario } = getState().usuariosReducer;
    const valida = validaUsuario(usuario);

    console.log(usuario);
    if (valida.hasError === true) {
      dispatch(errMensaje(valida.message));
      return;
    }

    const url = `${URL_BASE_USUARIOS}/PostUser`;
    const result = await post(url, usuario);

    if (result.code === 0) {
      dispatch(startGetUsuarios());
      dispatch(okMensaje(result.data));
    } else {
      console.log(result.error);
      dispatch(errMensaje(result.error));
    }
    ///
  };
};

const okMensaje = (mensaje) => {
  return (dispatch) => {
    dispatch(
      setMensaje({
        show: true,
        message: mensaje,
        type: "success",
      })
    );
  };
};

const errMensaje = (mensaje) => {
  return (dispatch) => {
    dispatch(
      setMensaje({
        show: true,
        message: mensaje,
        type: "error",
      })
    );
  };
};

const setMensaje = (payload) => ({
  type: types.setMensaje,
  payload,
});

/////////////

export const selectUsuario = (usuario) => {
  return async (dispatch) => {
    dispatch(setShowForma(true));
    dispatch(storeUsusario(usuario));
  };
};

export const setShowForma = (payload) => ({
  type: types.setShowForma,
  payload,
});

export const storeUsusario = (payload) => ({
  type: types.storeUsusario,
  payload,
});

export const startShowForma = () => {
  return (dispatch) => {
    dispatch(storeUsusario({}));
    dispatch(setShowForma(true));
  };
};

///////////

export const PostEliminar = (usuarioID) => {
  return async (dispatch) => {
    const url = `${URL_BASE_USUARIOS}/PostEliminar/${usuarioID}`;
    const result = await post(url);

    if (result.code === 0) {
      dispatch(startGetUsuarios());
      dispatch(okMensaje(result.data));
    } else {
      console.log(result.error);
      dispatch(errMensaje(result.error));
    }
    ///
  };
};

////////////////////////////////////////////////

export const startEstableceProveedorID = () => {
  return async (dispatch, getState) => {
    const { usuarioID, proveedorID } = getState().usuariosReducer.usuario;

    const body = { usuarioID, proveedorID };
    const url = `${URL_BASE_USUARIOS}/EstableceProveedorID`;
    const result = await post(url, body);

    if (result.code === 0) {
      dispatch(okMensaje(result.data));
    } else {
      dispatch(errMensaje(result.error));
    }
  };
};


/// 

export const startUpdateUsuarioProveedor = (body) => {
  return async (dispatch) => {
    const url = `${URL_BASE_USUARIOS}/UpdateUsuarioProveedor`;
    const result = await post(url, body);

    if (result.code === 0) {
      dispatch(startGetUsuarios());
      dispatch(notification(composeSuccessNotification(result.data)));
   
    } else {
      dispatch(notification(composeErrorNotification(result.error)));

    }
  };
}