import { RechazarPagoPrimerNivel } from "./_RechazarPagoPrimerNivel";
import { AutorizarPagoPrimerNivel } from "./_AutorizarPagoPrimerNivel";
import { Col, Row } from "antd";

export const PrimerNivel = () => {
  return (
    <>
      <Row gutter={10}>
        <Col>
          <RechazarPagoPrimerNivel />
        </Col>
        <Col>
          <AutorizarPagoPrimerNivel />
        </Col>
      </Row>
    </>
  );
};
