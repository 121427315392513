import { Fechas } from "./_Fechas";
import { Button, Col, Row } from "antd";
import { useGetData } from "./useGetData";

export const Controles = () => {
  const { body, handleChange, getData } = useGetData();

  return (
    <Row gutter={10}>
      <Col xs={16} lg={8}>
        <Fechas handleChange={handleChange} body={body} />
      </Col>
      <Col xs={8}>
        <Button onClick={getData} type="primary">
          Generar
        </Button>
      </Col>
    </Row>
  );
};
