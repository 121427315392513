import { Button, Popconfirm } from 'antd'
import React from 'react'
import { useDispatch } from 'react-redux'
import { startAutorizaOP2 } from '../../../../../../../context/actions/ordenesPagoActions'
import { useParams } from 'react-router-dom'

export const AutorizarPagoSegundoNivel = () => {
  const dispatch = useDispatch()
  const { ordenPagoID } = useParams()

  const submit = () => dispatch(startAutorizaOP2(ordenPagoID))
  
  return (
    <Popconfirm onConfirm={submit} title="¿Confirma Autorizar el Pago?">
    <Button style={{width: '100%'}} type="primary">Autorizar 2° Nivel</Button>
  </Popconfirm>
  )
}
