import React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import BotonSubmit from "../../../../../../components/Boton/BotonSubmit";
import LabelValor from "../../../../../../components/label/LabelValor";
import { ConfirmarFacturaModal } from "./ConfirmarFacturaModal";
import { correosConfirmaFactura } from "../../../../../../constants/cons";

export const ConfirmarFactura = () => {
  const {email} = useSelector((state) => state.userReducer.user);
  const [showModal, setShowModal] = useState(false);

  const { estatusOrdenPagoID, fechaSolicitudPago, usuarioSolicitudPago } =
    useSelector((state) => state.ordenesPagoReducer.ordenPago);

  if (estatusOrdenPagoID < 3) return <div></div>;
  if (estatusOrdenPagoID === 3) {
    if (correosConfirmaFactura.includes(email)) {
      return (
        <>
          <BotonSubmit
            style={{ marginTop: 18 }}
            tituloBoton="Confirmar Factura"
            onClick={() => setShowModal(true)}
          />
          <ConfirmarFacturaModal
            showModal={showModal}
            setShowModal={setShowModal}
          />
        </>
      );
    } else {
      return (
        <div
          style={{
            color: "red",
            fontWeight: 600,
            marginTop: 20,
            marginLeft: 10,
          }}
        >
          su usuario no puede confirmar factura
        </div>
      );
    }
  }

  return (
    <LabelValor
      label="Solicitud de pago"
      valor={`${fechaSolicitudPago} - ${usuarioSolicitudPago}`}
    />
  );
};
