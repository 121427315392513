import { Select } from "antd";
import { AccesoBackOfficeOptions } from "../../constants/cons";

const { Option } = Select;

export const SelectAccesoBackOffice = ({ value, onChange }) => {
  return (
    <>
      <div className="labelImput">Acceso a Módulo</div>
      <Select
        value={value}
        name="accesoBackOffice"
        showSearch
        onChange={onChange}
        style={{ width: "100%" }}
      >
        {AccesoBackOfficeOptions.map((a, i) => (
          <Option key={i} value={a}>
            {a}
          </Option>
        ))}
      </Select>
    </>
  );
};
