import { Col, Row, Select } from "antd";
import React from "react";
import { mesesOptions2 } from "../../../constants/cons";
import { aniosOptions2 } from "../../../utils/aniosOptions";


export const Fechas = ({ handleChange, body }) => {
  return (
    <Row gutter={10} style={{ marginBottom: 10 }}>
      <Col span={12}>
        <Select
          value={body.mes}
          placeholder="mes"
          style={{ width: "100%" }}
          onChange={(val) => handleChange("mes", val)}
          options={mesesOptions2}
        />
      </Col>
      <Col span={12}>
        <Select
          value={body.anio}
          placeholder="año"
          style={{ width: "100%" }}
          onChange={(val) => handleChange("anio", val)}
          options={aniosOptions2(2023, new Date().getFullYear())}
        />
      </Col>
    </Row>
  );
};
