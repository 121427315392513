import CardPage from "../../../layout/CardPage";
import { Content } from "./_Content";
import { Controles } from "./_Controles";

export const PagosPorPrograma = () => {
  return (
    <CardPage titulo="Pagos Por Programa">
      <Controles />
      <Content />
    </CardPage>
  );
};
