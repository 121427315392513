import { useSelector } from "react-redux";
import { PrimerNivel } from "./primerNivel/PrimerNivel";
import { SegundoNivel } from "./segundoNivel/SegundoNivel";

export const Controles = () => {
  const { fechaAutorizacionPago } = useSelector(
    (state) => state.ordenesPagoReducer.ordenPago
  );

  return (
    <div>
      {!fechaAutorizacionPago && <PrimerNivel />}
      {fechaAutorizacionPago && <SegundoNivel />}
    </div>
  );
};
