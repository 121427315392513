import { Table } from "antd";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { startGetOrdenesValidarFactura } from "../../../context/actions/ordenesPagoActions";
import CardPage from "../../../layout/CardPage";
import { useColumns } from "./useColumns";
import { UseNoRepeatArrayOP } from "../../../hooks/UseNoRepeatArrayOP";
import { StatsTable } from "../StatsTable";
import { getStats } from "../../../utils/getStatsOP";

export const ValidarFactura = () => {
  const dispatch = useDispatch();

  const { ordenesPagoValidarFactura } = useSelector((state) => state.ordenesPagoReducer);
  const arrayFilters = UseNoRepeatArrayOP(ordenesPagoValidarFactura,["razonSocial","proveedor", 'cliente', 'nombreCampania'])
  const { columns } = useColumns(arrayFilters);


  useEffect(() => {
    dispatch(startGetOrdenesValidarFactura()); // factura recibida
  }, [dispatch]);

  //
  const { totalCash, totalItems} = getStats(ordenesPagoValidarFactura)
  return (
    <CardPage>

      <StatsTable styles={{marginBottom: '5px'}} totalCash={totalCash} totalOrdenes={totalItems}/>
      <Table

        size="small"
        rowClassName="size12"
        rowKey="ordenPagoID"
        dataSource={ordenesPagoValidarFactura}
        columns={columns}
      />
    </CardPage>
  );
};
