import { useSelector } from "react-redux";
import { Autorizado } from "./autorizado/Autorizado";
import { Controles } from "./_Controles";

export const AutorizacionPago = () => {
  const { estatusOrdenPagoID } = useSelector(
    (state) => state.ordenesPagoReducer.ordenPago
  );

  // En la linea del tiempo no hemos llegado
  if (estatusOrdenPagoID < 4) {
    return <div></div>;
  }

  // por autorizar
  if (estatusOrdenPagoID === 4) {
    return <Controles />;
  }

  //Ya se autorizó
  return <Autorizado />;
};
