import { URL_BASE_REPORTES, URL_BASE_REPORTES2, URL_GENERICOS } from "../../constants/url";
import { composeErrorNotification } from "../../utils/composeNotification";
import { get, post } from "../../utils/fetch";
import { types } from "../types/types";
import { notification } from "./notificationActions";

export const startGetAsistenciasMes = () => {
  return async (dispatch, getState) => {
    dispatch(setLoadingReporte(true));

    const { mes, anio } = getState().reportesReducer.filtroReporte;
    const url = `${URL_BASE_REPORTES}/GetAsistenciasMes/${mes}/${anio}`;
    const result = await get(url);

    if (result.code === 0) {
      dispatch(storeAsistenciasMes(result.data));
    } else {
      dispatch(
        notification(
          composeErrorNotification("GetAsistenciasMes", `${result.error}`)
        )
      );
    }
    dispatch(setLoadingReporte(false));
  };
};

const setLoadingReporte = (estado) => ({
  type: types.setLoadingReporte,
  payload: estado,
});

export const setFiltroReporte = (ele) => ({
  type: types.setFiltroReporte,
  payload: ele,
});
const storeAsistenciasMes = (asistencias) => ({
  type: types.storeAsistenciasMes,
  payload: asistencias,
});

export const startGetAsistenciasDia = (fecha) => {
  return async (dispatch) => {
    dispatch(setLoadingReporte(true));

    const url = `${URL_BASE_REPORTES}/GetAsistenciasDia/${fecha}`;
    const result = await get(url);

    if (result.code === 0) {
      dispatch(storeAsistenciasDia(result.data));
    } else {
      dispatch(
        notification(
          composeErrorNotification("GetAsistenciasDia", `${result.error}`)
        )
      );
    }
    dispatch(setLoadingReporte(false));
  };
};

const storeAsistenciasDia = (asistencias) => ({
  type: types.storeAsistenciasDia,
  payload: asistencias,
});

export const startGetPagosProveedorByFecha = (body) => {
  return async (dispatch) => {
    dispatch(setLoadingReporte(true));

    const url = `${URL_BASE_REPORTES}/GetPagosProveedorByFecha`;
    const result = await post(url, body);

    console.log(result);
    if (result.code === 0) {
      dispatch(storePagosProveedorByFecha(result.data));
    } else {
      dispatch(
        notification(
          composeErrorNotification(
            "GetPagosProveedorByFecha",
            `${result.error}`
          )
        )
      );
    }
    dispatch(setLoadingReporte(false));
  };
};

const storePagosProveedorByFecha = (pagos) => ({
  type: types.storePagosProveedorByFecha,
  payload: pagos,
});


//

export const startGetReportePorMes = (body) => {
  return async (dispatch) => {
    dispatch(setLoadingReporte(true));

    const url = `${URL_BASE_REPORTES2}/GetReportePorMes`;
    const result = await post(url, body);
 
    if (result.code === 0) {
      dispatch(storeReportePorMes(result.data));
    } else {
      dispatch(
        notification(
          composeErrorNotification(
            "GetReportePorMes",
            `${result.error}`
          )
        )
      );
    }
    dispatch(setLoadingReporte(false));
  };
}

const storeReportePorMes = (reporte) => ({
  type: types.storeReportePorMes,
  payload: reporte,
})

export const startGetEstadosTodos = () => {
  return async (dispatch) => {
    const url = `${URL_GENERICOS}/GetEstadosTodos`;
    const result = await get(url);

    if (result.code === 0) {
      dispatch(storeEstadosTodos(result.data));
    } else {
      dispatch(
        notification(
          composeErrorNotification(
            "GetEstadosTodos",
            `${result.error}`
          )
        )
      );
    }
  };
};

const storeEstadosTodos = (estados) => ({
  type: types.storeEstadosTodos,
  payload: estados,
});


export const setVerTodo = (estado) => ({
  type: types.setVerTodo,
  payload: estado,
});

//

export const startGetPagosProveedorByPrograma = (body) => {
  return async (dispatch) => {
    dispatch(setLoadingReporte(true));

    const url = `${URL_BASE_REPORTES}/GetPagosProveedorByPrograma`;
    const result = await post(url, body);

    if (result.code === 0) {
      dispatch(storePagosProveedorByPrograma(result.data));
    } else {
      dispatch(
        notification(
          composeErrorNotification(
            "GetPagosProveedorByPrograma",
            `${result.error}`
          )
        )
      );
    }
    dispatch(setLoadingReporte(false));
  };
}

const storePagosProveedorByPrograma = (pagos) => ({
  type: types.storePagosProveedorByPrograma,
  payload: pagos,
})  