import { Button, Popconfirm } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { startAutorizaOP } from "../../../../../../../context/actions/ordenesPagoActions";
import { correosAutorizaOPPrimerNivel } from "../../../../../../../constants/cons";

export const AutorizarPagoPrimerNivel = () => {
  const {email} = useSelector((state) => state.userReducer.user);
  const dispatch = useDispatch();
  const { ordenPagoID } = useParams();

  const submit = () => dispatch(startAutorizaOP(ordenPagoID));

  if (!correosAutorizaOPPrimerNivel.includes(email)) {
    return <div></div>;
  }

  return (
    <Popconfirm onConfirm={submit} title="¿Confirma Autorizar el Pago?">
      <Button style={{width: '100%'}} type="primary">Autorizar 1° Nivel</Button>
    </Popconfirm>
  );
};
