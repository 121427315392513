import { Col, Row } from "antd";
import React from "react";
import LabelValor from "../../../../../../../components/label/LabelValor";
import { useSelector } from "react-redux";

export const AutorizadoPrimerNivel = () => {
  const { fechaAutorizacionPago, usuarioAutorizacionPago } = useSelector(
    (state) => state.ordenesPagoReducer.ordenPago
  );

  return (
    <Row gutter={10}>
      <Col span={24}>
        <LabelValor
          label="Autorización primer nivel"
          valor={`${fechaAutorizacionPago} - ${usuarioAutorizacionPago}`}
        />
      </Col>
    </Row>
  );
};
