import { Button, Input, Modal } from "antd";
import React, { useState } from "react";
import { TituloModal } from "../../../../../../../components/tituloModal/TituloModal";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch } from "react-redux";
import { startRechazarOP } from "../../../../../../../context/actions/ordenesPagoActions";

export const RechazarPagoPrimerNivel = () => {
  const dispatch = useDispatch();
  const { ordenPagoID } = useParams();
  const [open, setOpen] = useState(false);
  const [body, setBody] = useState({ ordenPagoID: Number(ordenPagoID) });

  const submit = () => {
    dispatch(startRechazarOP(body));
    setOpen(false);
    setBody({ ordenPagoID: Number(ordenPagoID) });
  };
  return (
    <>
      <Button style={{ width: "100%" }} danger onClick={() => setOpen(true)}>
        Rechazar Pago
      </Button>

      <Modal
        title={<TituloModal titulo="Rechazar Pago" />}
        open={open}
        onCancel={() => setOpen(false)}
        footer={[
          <Button key={1} onClick={() => setOpen(false)}>
            Cancelar
          </Button>,
          <Button disabled={!body.comentario} key={2} onClick={submit} danger>
            Rechazar Ahora
          </Button>,
        ]}
      >
        <Input.TextArea
          onChange={({ target }) =>
            setBody({ ...body, comentario: target.value })
          }
          placeholder="Motivo de Rechazo"
          value={body.comentario}
        />
      </Modal>
    </>
  );
};
