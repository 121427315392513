import { Col, Row } from "antd";
import React from "react";
import LabelValor from "../../../../../../../components/label/LabelValor";
import { useSelector } from "react-redux";

export const Autorizado = () => {
  const {
    estatusOrdenPagoID,
    fechaAutorizacionPago,
    usuarioAutorizacionPago,
    fechaAutorizacionPago2,
    usuarioAutorizacionPago2,
  } = useSelector((state) => state.ordenesPagoReducer.ordenPago);

  return (
    <>
      <Row gutter={[10,10]}>
        <Col span={24}>
          <LabelValor
            label="Autorización primer nivel"
            valor={`${fechaAutorizacionPago} - ${usuarioAutorizacionPago}`}
          />
        </Col>

        {fechaAutorizacionPago2 && (
          <Col span={24}>
            <LabelValor
              label="Autorización segundo nivel"
              valor={`${fechaAutorizacionPago2} - ${usuarioAutorizacionPago2}`}
            />
          </Col>
        )}
      </Row>
    </>
  );
};
