import React from "react";
import { useSelector } from "react-redux";
import { Item } from "./_Item";
import { Col, Row } from "antd";
import { Spinner } from "../dashboard/utils/Spinner";
import img from "../../../assets/emptyData.png";

export const Content = () => {
  const { pagosProveedorByPrograma, loadingReporte } = useSelector(
    (state) => state.reportesReducer
  );

  if (loadingReporte) return <Spinner />;

  return (
    <div style={{marginTop: 20}}>
      <Row className="tituloLista">
        <Col xs={15} lg={10} xl={8}>
          Cliente: Programa
        </Col>
        <Col xs={3}>Servicios</Col>
        <Col xs={6}>Total Pagado</Col>
      </Row>

      {pagosProveedorByPrograma.length === 0 && (
        <div style={{ textAlign: "center", marginTop: 20 }}>
          <img src={img} alt="No hay datos" style={{ width: 100 }} />
          <div> No hay datos </div>
        </div>
      )}

      {pagosProveedorByPrograma.map((a, i) => (
        <Item key={i} item={a} />
      ))}
    </div>
  );
};
