import { Spin } from "antd";
import { Suspense, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import { consLogged } from "../constants/consLogged";
import { startLoadInitial } from "../context/actions/initialActions";

import { LayoutPage } from "../layout/LayoutPage";

import Login from "../views/auth/Login";
import TiposAsistencia from "../views/tiposAsistencia/listado/Listado";
import TipoAsistenciaDetalle from "../views/tiposAsistencia/detalle/Detalle";

import Clientes from "../views/clientes/listado/Listado";
import ClienteDetalle from "../views/clientes/detalle/Detalle";
import CampaniaDetalle from "../views/clientes/detalleCampania/DetalleCampania";

import Home from "../views/home/Home";
import Proveedores from "../views/proveedores/listado/Listado";
import ProveedorDetalle from "../views/proveedores/detalle/Detalle";
//
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";

import Loading from "../components/loading/Loading";
import { Usuarios } from "../views/usuarios/Usuarios";
import { CreatePolygon } from "../views/proveedores/detalle/createPolygon/CreatePolygon";
import { Listas } from "../views/listas/Listas";
import { SucursalDetalle } from "../views/proveedores/sucursalDetalle/SucursalDetalle";
import { Asistencias } from "../views/asistencias/Asistencias";
import { EditarAsistencia } from "../views/asistencias/editarAsistencia/EditarAsistencia";
import { OrdenesPago } from "../views/ordenesPago/OrdenesPago";
import { Crear2 } from "../views/ordenesPago/crear2/Crear2";
import { Detalle } from "../views/ordenesPago/detalle/Detalle";
import { Asignacion } from "../views/asignacion/Asignacion";
import { Reportes } from "../views/reportes/Reportes";
import Calidad from "../views/calidad/Calidad";
import CalificarServicio from "../views/calidad/calidadServicio/CalificarServicio";
import { ReportesDia } from "../views/reportes/reportesDia/ReportesDia";
import { ReportesGeneral } from "../views/reportes/ReportesGeneral";
import { PagosProveedor } from "../views/reportes/pagosProveedor/PagosProveedor";
import { ReportePorMes } from "../views/reportes/reportePorMes/ReportePorMes";
import { PagosPorPrograma } from "../views/reportes/pagosPorPrograma/PagosPorPrograma";

export default function Routes() {
  const dispatch = useDispatch();

  const { logged } = useSelector((state) => state.userReducer);

  useEffect(() => {
    dispatch(startLoadInitial());
  }, [dispatch]);

  ///
  if (logged === consLogged.INICIANDO) return <Loading />;

  return (
    <Router>
      <div
        style={{
          height: "100vh",
          width: 15,
          backgroundColor: "#113061",
          position: "fixed",
          zIndex: 999,
        }}
      ></div>
      <Switch>
        <PublicRoute
          isAuthenticated={logged}
          exact
          path="/login"
          component={Login}
        />
        <LayoutPage>
          <Suspense
            fallback={
              <div style={{ marginTop: 30 }}>
                {" "}
                <Spin size="large" />
              </div>
            }
          >
            <PrivateRoute
              isAuthenticated={logged}
              exact
              path="/clientes"
              component={Clientes}
            />
            <PrivateRoute
              isAuthenticated={logged}
              exact
              path="/clientes/detalle/:clienteID"
              component={ClienteDetalle}
            />
            <PrivateRoute
              isAuthenticated={logged}
              exact
              path="/reportes"
              component={Reportes}
            />

<PrivateRoute
              isAuthenticated={logged}
              exact
              path="/reportes/pagosPorPrograma"
              component={PagosPorPrograma}
            />

            <PrivateRoute
              isAuthenticated={logged}
              exact
              path="/reportes/pagosProveedor"
              component={PagosProveedor}
            />
            <PrivateRoute
              isAuthenticated={logged}
              exact
              path="/reportes/dia"
              component={ReportesDia}
            />
            <PrivateRoute
              isAuthenticated={logged}
              exact
              path="/reportes/general"
              component={ReportesGeneral}
            />

            <PrivateRoute
              isAuthenticated={logged}
              exact
              path="/reportes/reportesPorMes"
              component={ReportePorMes}
            />

            <PrivateRoute
              isAuthenticated={logged}
              exact
              path="/usuarios"
              component={Usuarios}
            />
            <PrivateRoute
              isAuthenticated={logged}
              exact
              path="/clientes/detalleCampania/:clienteID/:campaniaID"
              component={CampaniaDetalle}
            />
            <PrivateRoute
              isAuthenticated={logged}
              exact
              path="/proveedores"
              component={Proveedores}
            />
            <PrivateRoute
              isAuthenticated={logged}
              exact
              path="/asignacion"
              component={Asignacion}
            />
            <PrivateRoute
              isAuthenticated={logged}
              exact
              path="/listas"
              component={Listas}
            />
            <PrivateRoute
              isAuthenticated={logged}
              exact
              path="/proveedores/detalle/:proveedorID"
              component={ProveedorDetalle}
            />
            <PrivateRoute
              isAuthenticated={logged}
              exact
              path="/proveedores/sucursalDetalle/:proveedorID/:proveedorSucursalID"
              component={SucursalDetalle}
            />
            <PrivateRoute
              isAuthenticated={logged}
              exact
              path="/proveedores/createPolygon/:proveedorID/:sucursalSelectedID"
              component={CreatePolygon}
            />
            <PrivateRoute
              isAuthenticated={logged}
              exact
              path="/tiposAsistencia"
              component={TiposAsistencia}
            />
            <PrivateRoute
              isAuthenticated={logged}
              exact
              path="/tiposAsistencia/detalle/:tipoAsistenciaID"
              component={TipoAsistenciaDetalle}
            />
            <PrivateRoute
              isAuthenticated={logged}
              exact
              path="/calidad"
              component={Calidad}
            />
            <PrivateRoute
              isAuthenticated={logged}
              exact
              path="/calidad/calificarServicio/:asistencia"
              component={CalificarServicio}
            />
            <PrivateRoute
              isAuthenticated={logged}
              exact
              path="/asistencias"
              component={Asistencias}
            />
            <PrivateRoute
              isAuthenticated={logged}
              exact
              path="/ordenesPago"
              component={OrdenesPago}
            />
            <PrivateRoute
              isAuthenticated={logged}
              exact
              path="/ordenesPago/crear"
              component={Crear2}
            />
            <PrivateRoute
              isAuthenticated={logged}
              exact
              path="/ordenesPago/detalle/:ordenPagoID"
              component={Detalle}
            />
            <PrivateRoute
              isAuthenticated={logged}
              exact
              path="/asistencias/editarAsistencia/:asistenciaID"
              component={EditarAsistencia}
            />
            <PrivateRoute
              isAuthenticated={logged}
              exact
              path="/"
              component={Home}
            />
          </Suspense>
        </LayoutPage>
      </Switch>
    </Router>
  );
}
