import React, { useEffect } from "react";
import { ProveedoresSelect } from "../../../components/select/ProveedoresSelect";
import { Button, Switch } from "antd";
import { useDispatch } from "react-redux";
import { startUpdateUsuarioProveedor } from "../../../context/actions/usuariosActions";

export const FormaUsuarioProveedor = ({ usuario, setOpen, setBusca }) => {
  const dispatch = useDispatch();
  const [body, setBody] = React.useState({});

  useEffect(() => {
    setBody(usuario);
  }, [usuario]);

  const cancel = () => {
    setBody({});
    setOpen(false);
  };

  const submit = () => {
    dispatch(startUpdateUsuarioProveedor(body));
    cancel();
    setBusca("");
  };

  const isDisabled = () => {
    if (body.esProveedor && !body.proveedorID) return true;
    return false;
  };

  if (usuario.email.includes("escotel"))
    return (
      <div>
        <div style={{ marginBottom: 10 }}>
          Usuario de Escotel, no puede ser proveedor
        </div>
        <div>
          <b>Usuario:</b> {body.nombre}
        </div>
        <div>
          <b> Email:</b> {body.email}
        </div>
      </div>
    );

  //
  return (
    <>
      <div>
        <b>Usuario:</b> {body.nombre}
      </div>
      <div>
        <b> Email:</b> {body.email}
      </div>
      <div style={{ marginTop: 10 }}>
        <Switch
          checkedChildren="Es Proveedor"
          unCheckedChildren="No es Proveedor"
          onChange={(val) => setBody({ ...body, esProveedor: val })}
          checked={body.esProveedor}
        />
      </div>

      {body.esProveedor && (
        <div style={{ marginTop: 10 }}>
          <ProveedoresSelect
            onChange={(val) => setBody({ ...body, proveedorID: val })}
            value={body.proveedorID}
          />
        </div>
      )}

      <div style={{ textAlign: "center", marginTop: 10 }}>
        <Button
          onClick={cancel}
          size="small"
          type="default"
          style={{ margin: 5 }}
        >
          Cancelar
        </Button>
        <Button
          disabled={isDisabled()}
          onClick={submit}
          size="small"
          type="primary"
          style={{ margin: 5 }}
        >
          Guardar Acceso
        </Button>
      </div>
    </>
  );
};
