import { getMonthNow } from "../../utils/getMonthNow";
import { getYearNow } from "../../utils/getYearNow";
import { types } from "../types/types";

const init = {
  asistenciasMes: [],
  filtroReporte: {
    mes: getMonthNow() + 1,
    anio: getYearNow(),
  },
  loadingReporte: false,
  asistenciasDia: {},
  pagosProveedorByFecha: { inicial: true },
  reportePorMes:{},
  estadosTodos: [],
  verTodo:false,
  pagosProveedorByPrograma:[]
};
export const reportesReducer = (state = init, action) => {
  switch (action.type) {

    case types.storePagosProveedorByPrograma:
      return {
        ...state,
        pagosProveedorByPrograma: action.payload,
      };

    case types.setVerTodo:
      return {
        ...state,
        verTodo: action.payload,
      };

    case types.storeEstadosTodos:
      return {
        ...state,
        estadosTodos: action.payload,
      };

    case types.storeReportePorMes:
      return {
        ...state,
        reportePorMes: action.payload,
      };

    case types.storePagosProveedorByFecha:
      return {
        ...state,
        pagosProveedorByFecha: action.payload,
      };
    case types.storeAsistenciasDia:
      return {
        ...state,
        asistenciasDia: action.payload,
      };
    case types.setFiltroReporte: {
      return {
        ...state,
        filtroReporte: {
          ...state.filtroReporte,
          ...action.payload,
        },
      };
    }
    case types.setLoadingReporte:
      return {
        ...state,
        loadingReporte: action.payload,
      };

    case types.storeAsistenciasMes:
      return {
        ...state,
        asistenciasMes: action.payload,
      };

    default:
      return state;
  }
};
