import { Input, Modal, Table } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import useColums from "./useColums";
import { FormaUsuarioProveedor } from "../formaUsuarioProveedor/FormaUsuarioProveedor";
import { TituloModal } from "../../../components/tituloModal/TituloModal";

////
export default function Tabla() {
  const [usuario, setUsuario] = useState({});
  const [open, setOpen] = useState(false);
  const [busca, setBusca] = useState("");

  const openUsuarioProveedor = (text) => {
    setUsuario(text);
    setOpen(true);
  };
  const { columnas } = useColums(openUsuarioProveedor);
  const datos = useSelector((state) => state.usuariosReducer.usuarios);

  const [listaFinal, setListaFinal] = useState([]);

  useEffect(() => {
    setListaFinal(datos);
  }, [datos]);

  ///

  const filter = ({ target }) => {
    setBusca(target.value);
    if (target.value !== "") {
      var newList = datos.filter(
        (a) =>
          (a.nombre &&
            a.nombre.toLowerCase().includes(target.value.toLowerCase())) ||
          (a.email &&
            a.email.toLowerCase().includes(target.value.toLowerCase()))
      );
      setListaFinal(newList);
    } else {
      setListaFinal(datos);
    }
  };

  ////
  return (
    <>
      <Input
        onChange={filter}
        placeholder="buscar"
        style={{ width: 300, marginBottom: 10 }}
        size="small"
        value={busca}
      />
      <Table
        size="small"
        rowKey="usuarioID"
        columns={columnas}
        dataSource={listaFinal}
      />

      <Modal
        title={<TituloModal titulo="Usuario de Proveedor" />}
        open={open}
        onCancel={() => setOpen(false)}
        footer={null}
      >
        <FormaUsuarioProveedor usuario={usuario} setOpen={setOpen} setBusca={setBusca} />
      </Modal>
    </>
  );
}
