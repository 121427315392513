import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { startGetPagosProveedorByPrograma } from "../../../context/actions/reportesActions";

export const useGetData = () => {
  const dispatch = useDispatch();
  const anio = new Date().getFullYear();
  const mes = new Date().getMonth() + 1;
  const [body, setBody] = useState({ mes, anio });



  const getData = () => {
    
    dispatch(startGetPagosProveedorByPrograma(body));
  };

  const handleChange = (key, val) => {
    setBody({ ...body, [key]: val });
  };

  return {
    body,
    handleChange,
    getData,
  };
};
