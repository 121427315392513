import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { startGetProveedores } from "../../context/actions/proveedoresActions";
import { startGetUsuarios } from "../../context/actions/usuariosActions";

import { UsuariosListado } from "./usuariosListado/UsuariosListado";

export const Usuarios = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(startGetUsuarios());
    dispatch(startGetProveedores());
  }, [dispatch]);

  return (
    <>
      <UsuariosListado />
    </>
  );
};
