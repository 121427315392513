import {
  EyeOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  BankOutlined,
} from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { startGetAcesoBackOffice } from "../../../context/actions/accesoBackOfficeActions";
import { selectUsuario } from "../../../context/actions/usuariosActions";
import { Button, Modal, Space, Tooltip } from "antd";
import { useState } from "react";
import { FormaUsuarioProveedor } from "../formaUsuarioProveedor/FormaUsuarioProveedor";
import { TituloModal } from "../../../components/tituloModal/TituloModal";

////
export default function useColums(openUsuarioProveedor) {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const handleClick = (usuario) => {
    dispatch(selectUsuario(usuario));
    dispatch(startGetAcesoBackOffice());
  };

  const columnas = [
    {
      title: "Nombre",
      dataIndex: "nombre",
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: "Cabina",
      dataIndex: "esCabina",
      render: (text, record) => getIcon(text),
    },
    {
      title: "Admin",
      dataIndex: "esBackoffice",
      render: (text, record) => getIcon(text),
    },
    {
      title: "Cliente",
      dataIndex: "esCliente",
      render: (text, record) => getIcon(text),
    },
    {
      title: "Proveedor",
      dataIndex: "esProveedor",
      render: (text, record) => getIcon(text),
    },

    {
      title: "Creado",
      dataIndex: "fechaCreacion",
    },
    {
      title: "Ultimo login",
      dataIndex: "ultimoLogin",
    },

    {
      title: "",
      width: 30,
      render: (row, record) => (
        <>
          <Space>
            <Tooltip title="Abrir Usuario">
              <EyeOutlined
                onClick={() => handleClick(record)}
                style={{ color: "#ca2228" }}
                className="eyeIcon"
              />
            </Tooltip>
            <Tooltip title="Accceso Proveedor">
              <BankOutlined
                onClick={() => openUsuarioProveedor(row)}
                style={{ color: "#ca2228" }}
                className="eyeIcon"
              />
            </Tooltip>
          </Space>

         
        </>
      ),
    },
  ];

  return { columnas };
}

const getIcon = (boleano) => {
  if (boleano === true)
    return (
      <div style={{ textAlign: "center" }}>
        <CheckCircleOutlined style={{ color: "green" }} />
      </div>
    );
  return (
    <div style={{ textAlign: "center" }}>
      <CloseCircleOutlined style={{ color: "red" }} />
    </div>
  );
};
