import { Col, Row } from 'antd'
import React from 'react'
import { formatCurrency } from '../../../utils/formatCurrency'

export const Item = ({item}) => {
  return (
   <Row className='rowReporte'>
    <Col xs={15} lg={10} xl={8}>{item.cliente}</Col>
    <Col xs={3} style={{textAlign: 'center'}}>{item.cantidad}</Col>
    <Col xs={6} style={{textAlign: 'right'}}>{formatCurrency(item.suma)}</Col>
   </Row>
  )
}
